import * as React from "react"
import '../../css/bootstrap.css';
import {ThemeProvider} from "react-bootstrap";

import "../../fonts/font-awesome/css/font-awesome.min.css";
import "../../css/template.css";
import "../../css/responsive.css";
import "../../css/base-sizing.css";
import "../../css/custom.css";
import "../../css/knowledge.css";

import HeaderAndMenu from "../../page_components/common/HeaderAndMenu";
import Footer from "../../page_components/common/Footer";
import BsHelmet from "../../page_components/common/BsHelmet";
import CommonHeroLow from "../../page_components/common/CommonHeroLow";
import BreadCrumbs from "./breadcrumbs";
import {StaticImage} from "gatsby-plugin-image";
import {useEffect} from "react";
import initStickyMenu from "../../page_components/common/initStickyMenu";


const ApiKeys = () => {
    useEffect(() => {
        initStickyMenu();
    }, []);

    return (
        <ThemeProvider breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}>
            <BsHelmet title={"BitSpreader Documentation - Exchange & API Keys setup"}/>
            <div id="page_wrapper">
                <HeaderAndMenu/>
                <CommonHeroLow title={"BitSpreader Documentation - Exchange & API Keys setup"}/>
                <BreadCrumbs link={"apikeys"} title="Exchange & API Keys setup"/>
                <section className="hg_section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <div id="stickymenu" className="sidebar knowledgebase">
                                    <div className="widget widget_categories">
                                        <h3 className="widgettitle title">SECTIONS</h3>
                                        <a href="#apikeys">Exchange & API Keys setup</a>
                                        <ul className="menu">
                                            <li><a href="#security">Security</a></li>
                                            <li><a href="#add">Adding new API Key</a></li>
                                            <li><a href="#permissions">API Key Permissions</a></li>
                                            <li><a href="#status">API Key status</a></li>
                                            <li><a href="#remove">Removing API Key</a></li>
                                            <li><a href="#specific">Exchange settings</a>
                                                <ul className={"menu pl-30"}>
                                                    <li><a href={"#binance"}>Binance</a></li>
                                                    <li><a href={"#bitmex"}>Bitmex</a></li>
                                                    <li><a href={"#deribit"}>Deribit</a></li>
                                                    <li><a href={"#huobi"}>Huobi</a></li>
                                                    <li><a href={"#kraken"}>Kraken</a></li>
                                                    <li><a href={"#kucoin"}>KuCoin</a></li>
                                                    <li><a href={"#okx"}>OKX</a></li>
                                                </ul>
                                            </li>
                                        </ul>
                                        <a href="#leverage">Leverage setup</a>
                                    </div>
                                </div>
                            </div>
                            <div className={"col-md-8"}>
                                <h2 id="stickyref" className="kb-article--title">
                                    <a name={"apikeys"}>Exchange & API Keys setup</a>
                                </h2>

                                <br/><br/>
                                <a name={"security"}><h4>Security</h4></a>

                                <div className={"row"}>
                                    <div className={'col-md-12 text-justify'}>
                                        <br/>
                                        Your funds are stored safely on your exchange account, BitSpreader just executes orders on your behalf on the exchange using the API key that you have provided.

                                        <br/><br/><b>BitSpreader never does any transfers of your funds.</b>

                                        <br/><br/>For further increase of security we strongly encourage you to not provide any API keys that have privilege to execute transfers - please refer to your
                                        exchange
                                        API key settings to make sure to reduce the API key privileges just to the most necessary (balance, history, trading).

                                        <br/><br/><b>Your data is encrypted</b>
                                        <br/><br/>Your API keys are encrypted and available only in the internal layer of the BitSpreader services safely hidden behind the firewalls and not accessible
                                        from the
                                        website.

                                        <br/><br/>For managing your API keys we follow write-only pattern - once you have provided your API key, the BitSpreader has stored it internally for the
                                        trading purposes
                                        and doesn't expose it even to you for editing. If you need to update the API key - you need to delete the old key and provide the updated one.

                                    </div>
                                </div>
                                <br/><br/>

                                <a name={"add"}><h4>Adding new API Key</h4></a>
                                <div className={"row"}>
                                    <div className={'col-md-6 text-justify'}>
                                        <br/>
                                        In order to access the API Key management panel, click on the “API Keys” option in the sliding sidebar on the left side of the screen. Users can define any
                                        number of API keys with following limitations:

                                        <ul className={"list-style1 pt-20"}>
                                            <li>There can be only one API key for given exchange</li>
                                            <li>After adding an API Key, the user cannot see it and cannot update it - the only action that can be performed is deleting the API key. In case the user
                                                made a mistake when entering the api key, user needs to delete invalid api key and add the correct one again
                                            </li>

                                        </ul>
                                    </div>
                                    <div className={'col-md-6 text-center'}>
                                        <StaticImage src={"../../gatsbyimg/docs/openapikeys.png"} alt={"Registration#1"} className={"border"} width={150}/>
                                    </div>
                                </div>


                                <div className={"row"}>
                                    <div className={'col-md-6 text-justify'}>
                                        <br/>
                                        Click “Add API Key” button, select an exchange to which you don’t have any API key yet, provide API key and secret key. Some exchanges may require you to
                                        provide additional parameter i.e. passphrase - please provide it in the last box.
                                    </div>
                                    <div className={'col-md-6'}>
                                        <StaticImage src={"../../gatsbyimg/docs/addapikeyform.png"} alt={"Registration#1"} className={"border"}/>
                                    </div>
                                </div>


                                <br/><br/>
                                <a name={"permissions"}><h4>API Key Permissions</h4></a>
                                <div className={"row"}>
                                    <div className={'col-md-6 text-justify'}>
                                        <br/>
                                        When creating a new API Key on the exchange please make sure that the API Key has following permissions:

                                        <ul className={"list-style1 pt-20"}>

                                            <li>Access to balances/wallet information</li>
                                            <li>Trading on futures</li>
                                            <li>Accessing transaction list</li>
                                        </ul>

                                    </div>
                                    <div className={'col-md-6'}>
                                    </div>
                                </div>


                                <br/><br/>
                                <a name={"status"}><h4>API Key status</h4></a>
                                <div className={"row"}>
                                    <div className={'col-md-6 text-justify'}>
                                        <br/>
                                        After entering the API Keys management panel you see the list of exchanges to which you have active API keys along with the status of the API key. The key can
                                        have INVALID status in case:
                                        <ul className={"list-style1 pt-20"}>

                                            <li>Key data was invalid</li>
                                            <li>Key has expired and is no longer accepted by the exchange</li>
                                            <li>The exchange returned and API key error during the initialization</li>
                                        </ul>

                                    </div>
                                    <div className={'col-md-6'}>
                                        <StaticImage src={"../../gatsbyimg/docs/apikeys.png"} alt={"Registration#1"} className={"border"}/>
                                    </div>
                                </div>

                                <br/><br/>
                                <a name={"remove"}><h4>Removing API Key</h4></a>
                                <div className={"row"}>
                                    <div className={'col-md-12 text-justify'}>
                                        <br/>

                                        After clicking an API Key on the list, you can see the first three characters of the api key (to enable the user to identify the key in case there is more than
                                        one on a given exchange) and an option to delete this key from BitSpreader.


                                    </div>

                                </div>

                                <br/><br/>
                                <a name={"specific"}><h4>Exchange specific requirements</h4></a>
                                <div className={"row"}>
                                    <div className={'col-md-12 text-justify'}>
                                        <br/><a href="#" name={"binance"}/><h4><b>Binance</b></h4>
                                        <b>API KEY setup</b><br/><br/>
                                        Binance has two separate APIs for futures - one for coin-margined (COIN-M) futures and another one for usds-margined (USD-M) futures. You will need to define
                                        two separate API key definitions in
                                        BitSpreader if you want to trade on both types - select proper exchange name on the exchange list when defining API Key:
                                        <br/>
                                        <ul className={"list-style1 pt-20 pb-20"}>
                                            <li>Binance USD-M futures - for USD margined</li>
                                            <li>Binance COIN-M Futures - for coin margined</li>
                                        </ul>
                                        On the Binance side you only need to define the key once and you can use the same key value and secret for both API key definitions in BitSpreader

                                        <br/><br/><b>API KEY permissions</b><br/><br/>
                                        Before creating the API key, disable the <b>Default Security Controls.</b> option. This will allow to enable trading on futures without providing multiple IP addresses of servers used by BitSpreader.
                                        <br/><br/>
                                        <StaticImage src={"../../gatsbyimg/docs/binanceapikeysetup0.png"} alt={"Disable Default Security Controls"} className={"border"}/>
                                        <br/><br/>

                                        After creating the API key, make sure to click <b>"Edit permissions"</b> button and update the API key permissions - you need to check the option <b>"Enable
                                        Futures"</b> and don't remember to click <b>Save</b>.
                                        <br/><br/>
                                        <StaticImage src={"../../gatsbyimg/docs/binanceapikeysetup.png"} alt={"Setup Binance Futures API Key"} className={"border"}/>

                                        <br/><br/><b>Load funds into Binance futures trading wallet</b><br/><br/>
                                        Binance has separate wallets for each type of trading account. For futures trading it has two separate wallets for COIN-M futures and for USD-M futures.


                                        <br/><br/>
                                        <b>Coin margined (COIN-M) futures </b> use base currency of the instrument as the currency in which margin is controlled. Before you trade with these futures,
                                        make sure you transfer some BTC to your COIN-M wallet.
                                        <br/>In the top bar select <b>Wallet</b>
                                        <br/><br/>
                                        <StaticImage src={"../../gatsbyimg/docs/binancetopbar.png"} alt={"Setup Binance Futures API Key - top bar"} className={"border"}/>
                                        <br/><br/>You are in the Wallet overview, click <b>Transfer</b>
                                        <br/><br/>
                                        <StaticImage src={"../../gatsbyimg/docs/binancewallet.png"} alt={"Setup Binance Futures API Key - wallet"} className={"border"}/>
                                        <br/><br/>Select the source wallet, target wallet, set currency to BTC, choose amount and click <b>Transfer</b>
                                        <br/><br/>
                                        <div className={"text-center"}><StaticImage src={"../../gatsbyimg/docs/binancetransfertocoinm.png"} width="300"
                                                                                    alt={"Setup Binance Futures API Key - transfer to coinm"} className={"border"}/></div>

                                        <br/><br/>
                                        <b>USD(S) margined (USDS-M) futures </b> use base stable coin - either USDT or BUSD as the currency in which margin is controlled. Before you trade with these
                                        futures,
                                        make sure you transfer some USDT or BUSD to your USD-M wallet. If you want to trade on BTC-USDT contracts - load USDT, for BTC-BUSD contracts - load BUSD
                                        <br/><br/>For USD-M when transfering the funds select the source wallet, target wallet, set currency to USDT/BUSD, choose amount and click <b>Transfer</b>
                                        <br/><br/>
                                        <div className={"text-center"}><StaticImage src={"../../gatsbyimg/docs/binancetransfertousdm.png"} width="300"
                                                                                    alt={"Setup Binance Futures API Key - transfer to usdm"} className={"border"}/></div>

                                        <br/><br/><b>Position mode</b><br/><br/>
                                        Binance supports two position modes: One-way and Hedge. Currently BitSpreader supports only One-way meaning a symbol only supports holding a position in one
                                        direction.
                                        In case Hedge mode is set in Binance preferences BitSpreader will not be able to open and close positions. Please ensure that Position Mode is set to One-way
                                        <br/><br/>
                                        <div className={"text-center"}><StaticImage src={"../../gatsbyimg/docs/binancepositionmode.png"} width="300"
                                                                                    alt={"Setup Position Mode"} className={"border"}/></div>

                                        <br/><br/><b>How much funds do I need in the futures wallet</b><br/><br/>
                                        As the default leverage for Binance is 20, that means that if we want to open position with size X, we need to provide equity on our account called margin equal
                                        to X/20. To start spread trade with size of 1000 dollars, we need to provide the equity worth 100 dollars on our trading account as a margin for a spread trade,
                                        50
                                        dollars for each of the legs of the spread trade.


                                        <br/><br/><a name={"binanceleverage"} href={"#"}><b>Setting the default leverage on Binance</b></a><br/><br/>
                                        Binance manages the leverage per each individual instrument.
                                        <br/><br/>
                                        <ul className={"list-style1"}>
                                            <li>Minimum leverage: 1x</li>
                                            <li>Maximum leverage: 50x</li>
                                            <li>Default leverage: 20x</li>
                                        </ul>
                                        <br/><br/>In order to change the default leverage for currently selected instrument click on the "20x" (or current leverage value) in the top right corner of the Binance
                                        trading platform:
                                        <div className={"text-center"}><StaticImage src={"../../gatsbyimg/docs/binance_leverage_1.png"}
                                                                                    alt={"Setup Binance Leverage"} className={"border mt-30 mb-30"}/></div>
                                        And then change the leverage to the preferred value:
                                        <div className={"text-center"}><StaticImage src={"../../gatsbyimg/docs/binance_leverage_2.png"} width={300}
                                                                                    alt={"Setup Binance Leverage"} className={"border mt-30 mb-30"}/></div>

                                        <br/><br/><b>Important</b><br/><br/>
                                        <ul className={"list-style1"}>
                                            <li>BitSpreader currently doesn't support the margin control - if you don't have enough funds on your account available, exchange will return errors</li>
                                        </ul>

                                    </div>

                                </div>

                                <div className={"row"}>
                                    <div className={'col-md-12 text-justify'}>
                                        <br/><a href="#" name={"bitmex"}/><h4><b>Bitmex</b></h4>
                                        <br/><b>API KEY permissions</b><br/><br/>
                                        To create API Key on Bitmex exchange please visit <a href={"https://www.bitmex.com/app/apiKeys"} name={"API Keys"}>"API Keys"</a>.<br/>
                                        When creating the API key please make sure Key Permissions are set to "Order".
                                        <br/><br/>
                                        <div className={"text-center"}><StaticImage src={"../../gatsbyimg/docs/bitmex_apikey_setup_1.png"} alt={"Setup Bitmex API Key"} className={"border"}/></div>
                                        <br/><br/>
                                        When API Key is created you will be presented with "ID" and "Secret" values:
                                        <br/><br/>
                                        <div className={"text-center"}><StaticImage src={"../../gatsbyimg/docs/bitmex_apikey_setup_2.png"} alt={"Setup Bitmex API Key"} className={"border"}/></div>
                                        <br/><br/>
                                        Please use "ID" as "API KEY" and "Secret" as "SECRET KEY" when adding Api Key to BitSpreader.

                                        <br/><br/><b>How much funds do I need in the futures wallet</b><br/><br/>
                                        As the default leverage for Bitmex is 100, that means that if we want to open position with size X, we need to provide equity on our account called margin equal
                                        to X/100. To start spread trade with size of 1000 dollars, we need to provide the equity worth 20 dollars on our trading account as a margin for a spread trade,
                                        10 dollars for each of the legs of the spread trade.

                                        <br/><br/><b>Isolated and Cross Margin</b><br/><br/>
                                        Please note that by default Bitmex uses Cross Margin meaning that margin is shared between open positions with the same settlement cryptocurrency.
                                        Alternatively one can use Isolated Margin which means that liability is limited to the initial margin set at order creation.
                                        For more details please visit bitmex support page <a href={"https://www.bitmex.com/app/isolatedMargin"} name={"Isolated and Cross Margin"}>"Isolated and Cross Margin"</a>.

                                        <br/><br/><a name={"bitmexleverage"} href={"#"}><b>Setting the default leverage on Bitmex</b></a><br/><br/>
                                        Bitmex manages the leverage per each individual instrument.
                                        <br/><br/>
                                        <ul className={"list-style1"}>
                                            <li>Minimum leverage: 1x</li>
                                            <li>Maximum leverage: 100x with Cross Margin</li>
                                            <li>Default leverage: 100x with Cross Margin</li>
                                        </ul>
                                        <br/><br/>To change the default leverage for an instrument select it on Bitmex web interface and using slider under "Your position" choose either cross margin or isolated leverage between 1-100x.
                                        trading platform:
                                        <div className={"text-center"}><StaticImage src={"../../gatsbyimg/docs/bitmex_leverage.png"}
                                                                                    alt={"Setup Bitmex Leverage"} className={"border mt-30 mb-30"}/></div>

                                        <br/><br/><b>Important</b><br/><br/>
                                        <ul className={"list-style1"}>
                                            <li>BitSpreader currently doesn't support the margin control - if you don't have enough funds on your account available, exchange will return errors</li>
                                        </ul>

                                    </div>

                                </div>

                                <div className={"row"}>
                                    <div className={'col-md-12 text-justify'}>
                                        <br/><a href="#" name={"deribit"}/><h4><b>Deribit</b></h4>
                                        <br/><b>API KEY permissions</b><br/><br/>
                                        After creating the API key, make sure to set proper permissions on the API Key:
                                        <br/><br/>
                                        <ul className={"list-style1"}>
                                            <li>Account: read</li>
                                            <li>Wallet: read</li>
                                            <li>Trade: read_write</li>
                                        </ul>
                                        <br/><br/>
                                        <div className={"text-center"}><StaticImage src={"../../gatsbyimg/docs/deribitpermissions.png"} width={300} alt={"Setup Deribit API Key"} className={"border"}/>
                                        </div>

                                        <br/><br/><b>Load funds into Deribit futures trading wallet</b><br/><br/>
                                        You need to make sure that you have funds in the currency that is suitable as a margin for selected type of futures. Majority of the futures on Deribit are coin
                                        margined - that means that you need to provide the equity in your wallet in the base currency (ie BTC) of the contracts as the margin. In case you wanted to
                                        trade on
                                        BTC-USDC perpetual, you need to provide a margin in USDC.

                                        <br/><br/><b>How much funds do I need in the futures wallet</b><br/><br/>
                                        As the default leverage for Deribit is 50, that means that if we want to open position with size X, we need to provide equity on our account called margin equal
                                        to X/50. To start spread trade with size of 1000 dollars, we need to provide the equity worth 40 dollars on our trading account as a margin for a spread trade,
                                        20
                                        dollars for each of the legs of the spread trade.
                                        <br/><br/><a name={"deribitleverage"} href={"#"}><b>Setting the default leverage on Deribit</b></a><br/><br/>
                                        Deribit has <b>fixed leverage 50x</b>, there is no way user can change the leverage.
                                        <br/><br/>

                                        <br/><br/><b>Important</b><br/><br/>
                                        <ul className={"list-style1"}>
                                            <li>BitSpreader currently doesn't support the margin control - if you don't have enough funds on your account available, exchange will return errors</li>
                                        </ul>

                                    </div>

                                </div>

                                <div className={"row"}>
                                    <div className={'col-md-12 text-justify'}>
                                        <br/><a href="#" name={"huobi"}/><h4><b>Huobi</b></h4>
                                        <br/><b>API KEY permissions</b><br/><br/>
                                        When creating the API key please make sure proper API Key permissions are set. You need following permissions on your API key
                                        <br/><br/>
                                        <ul className={"list-style1"}>
                                            <li>Read-only</li>
                                            <li>Trade</li>
                                        </ul>
                                        <br/><br/>
                                        <div className={"text-center"}><StaticImage src={"../../gatsbyimg/docs/huobi_permissions.png"} alt={"Setup HUOBI API Key"} className={"border"}/></div>

                                        <br/><br/><b>Load funds into Huobi futures trading wallet</b><br/><br/>
                                        BitSpreader currently supports only coin-margined futures with delivery dates. <b>Perpetuals are not supported</b>. You need to fund your account with BTC.


                                        <br/><br/><b>How much funds do I need in the futures wallet</b><br/><br/>
                                        As the default leverage for Huobi is 5, that means that if we want to open position with size X, we need to provide equity on our account called margin equal
                                        to X/5. To start spread trade with size of 1000 dollars, we need to provide the equity worth 200 dollars on our trading account as a margin for a spread trade,
                                        100 - dollars for each of the legs of the spread trade.

                                        <br/><br/><a name={"huobileverage"} href={"#"}><b>Setting the default leverage on Huobi</b></a><br/><br/>
                                        Huobi manages the leverage per each individual instrument.
                                        <br/><br/>
                                        <ul className={"list-style1"}>
                                            <li>Minimum leverage: 1x</li>
                                            <li>Maximum leverage: 50x</li>
                                            <li>Default leverage: 5x</li>
                                        </ul>
                                        <br/><br/>In order to change the default leverage for currently selected instrument click on the "5x" (or current leverage value) in the top right corner of the Huobi
                                        trading platform:
                                        <div className={"text-center"}><StaticImage src={"../../gatsbyimg/docs/huobi_leverage_1.png"}
                                                                                    alt={"Setup Binance Leverage"} className={"border mt-30 mb-30"}/></div>
                                        And then change the leverage to the preferred value:
                                        <div className={"text-center"}><StaticImage src={"../../gatsbyimg/docs/huobi_leverage_2.png"} width={300}
                                                                                    alt={"Setup Binance Leverage"} className={"border mt-30 mb-30"}/></div>

                                        <br/><br/><b>Important</b><br/><br/>
                                        <ul className={"list-style1"}>
                                            <li>BitSpreader currently doesn't support the margin control - if you don't have enough funds on your account available, exchange will return errors</li>
                                        </ul>

                                    </div>

                                </div>

                                <div className={"row"}>
                                    <div className={'col-md-12 text-justify'}>
                                        <br/><a href="#" name={"kraken"}/><h4><b>Kraken</b></h4>
                                        <br/><b>API KEY permissions</b><br/><br/>
                                        When creating the API key please make sure proper API Key permissions are set. You need following permissions on your API key
                                        <br/><br/>
                                        <ul className={"list-style1"}>
                                            <li>"Full access" permissions are required under "General API"</li>
                                            <li>no access is required under "Withdrawal API"</li>
                                        </ul>
                                        <br/><br/>
                                        <div className={"text-center"}><StaticImage src={"../../gatsbyimg/docs/krakenfutures_apikey.png"} alt={"Setup Kraken API Key"} className={"border"}/></div>

                                        <br/><br/><b>Load funds into Kraken futures single collateral trading wallet</b><br/><br/>
                                        BitSpreader currently supports only coin-margined futures traded using single-collateral wallet. You need to transfer your funds from Holding Wallet to Single Collateral wallet to trade.<br/>
                                        To do so, open Single Collateral Wallets directly using link <a href={"https://futures.kraken.com/trade/wallets/single-collateral/"} name={"Single Collateral Wallets on futures.kraken.com"}>"futures.kraken.com/trade/wallets/single-collateral"</a>
                                        or open <a href={"https://futures.kraken.com/"} name={"Kraken Futures platform"}>"futures.kraken.com"</a>, open "Wallets" and select "Single Collateral Futures".
                                        <div className={"text-center"}><StaticImage src={"../../gatsbyimg/docs/krakenfutures_singlecollateral_wallet_transfer_1.png"} alt={"Transfer funds to Single Collateral wallet"} className={"border"}/></div><br/>
                                        On this screen you can use Transfer" option to transfer funds between Holding Wallets and Single Collateral Wallets.
                                        <div className={"text-center"}><StaticImage src={"../../gatsbyimg/docs/krakenfutures_singlecollateral_wallet_transfer_2.png"} alt={"Transfer funds to Single Collateral wallet"} className={"border"}/></div>


                                        <br/><br/><b>How much funds do I need in the futures wallet</b><br/><br/>
                                        Maximum leverage for Kraken Futures is 50. That means that if we want to open position with size X, we need to provide equity on our account called margin equal
                                        to X/50. To start spread trade with size of 1000 dollars, we need to provide the equity worth 40 dollars on our trading account as a margin for a spread trade,
                                        20 - dollars for each of the legs of the spread trade.

                                        <br/><br/><a name={"krakenleverage"} href={"#"}><b>Setting the default leverage on Kraken Futures</b></a><br/><br/>
                                        Kraken Futures does not allow for isolating a margin amount in your order or resulting position. All funds in your Single Collateral Wallet are at risk when you have orders and positions that are active.
                                        To reduce risk you can reduce funds kept in Single Collateral Wallet to only those meant to be used in trading or as collateral. Rest of funds can be kept safely on Funding Wallet.

                                    </div>

                                </div>


                                <div className={"row"}>
                                    <div className={'col-md-12 text-justify'}>
                                        <br/><a href="#" name={"kucoin"}/><h4><b>KuCoin</b></h4>
                                        <br/><b>API KEY permissions</b><br/><br/>
                                        When creating the API key please make sure proper API Key restrictions are set. You need following permissions on your API key
                                        <br/><br/>
                                        <ul className={"list-style1"}>
                                            <li>"General" permissions which is enabled by default</li>
                                            <li>"Futures Trading" required in order to trade futures</li>
                                        </ul>
                                        <br/><br/>
                                        <div className={"text-center"}><StaticImage src={"../../gatsbyimg/docs/kucoin_apikey_permissions.png"} alt={"Setup KuCoin API Key"} className={"border"}/></div>

                                        <br/><br/><b>Load funds into KuCoin futures account</b><br/><br/>
                                        BitSpreader supports both: coin-margined and usd-margined futures traded using single-collateral wallet. You need make transfer of your funds with two steps:<br/>
                                        To do so, open Funding Account (or main account) and deposit assets.


                                        <div className={"text-center"}><StaticImage src={"../../gatsbyimg/docs/kucoin_main_account.png"} alt={"Transfer funds to Funding Account"} className={"border"}/></div><br/>
                                        On Funding Account  screen you can use "Deposit" option to transfer funds between Holding Wallets and Funding Account. <br />
                                        When transfer is done then you need to transfer funds from Funding Account to Futures Account. Go to Futures Account and use "Transfer" option to transfer funds from Funding Account to Futures Account.

                                        <div className={"text-center"}><StaticImage src={"../../gatsbyimg/docs/kucoin_futures_account.png"} alt={"Transfer funds to Futures Account"} className={"border"}/></div><br/>
                                        <div className={"text-center"}><StaticImage src={"../../gatsbyimg/docs/kucoin_transfer_from_main_to_futures_account.png"} alt={"Transfer funds to Futures Account"} className={"border"}/></div>

                                        <br/><br/><b>How much funds do I need in the futures wallet</b><br/><br/>
                                        Maximum leverage on futures is 75. That means that if we want to open position with size X, we need to provide equity on our account called margin equal
                                        to X/75. To start spread trade with size of 1000 dollars, we need to provide the equity worth 28 dollars on our trading account as a margin for a spread trade,
                                        14 - dollars for each of the legs of the spread trade.

                                        <br/><br/><a name={"kucoinleverage"} href={"#"}><b>Setting the default leverage on KuCoin</b></a><br/><br/>
                                        All funds in your Futures Account wallet are at risk when you have orders and positions that are active.
                                        To reduce risk you can reduce funds kept in Futures Account to only those meant to be used in trading or as collateral. Rest of funds can be kept safely on Funding Wallet.

                                        <br/><br/>To change the default leverage for an instrument select it on KuCoin web interface and using slider between 1-100x.
                                        <div className={"text-center"}><StaticImage src={"../../gatsbyimg/docs/kucoin-setting-leverage.png"}/></div><br/>


                                        </div>

                                </div>

                                <div className={"row"}>
                                    <div className={'col-md-12 text-justify'}>
                                        <br/><a href="#" name={"okx"}/><h4><b>OKX</b></h4>
                                        <br/><b>API KEY permissions</b><br/><br/>
                                        When creating the API key please make sure proper API Key permissions are set. You need following permissions on your API key
                                        <br/><br/>
                                        <ul className={"list-style1"}>
                                            <li>Read</li>
                                            <li>Trade</li>
                                        </ul>
                                        <br/><br/>
                                        <div className={"text-center"}><StaticImage src={"../../gatsbyimg/docs/okx_apikey.png"} alt={"Setup OKX API Key"} className={"border"}/></div>

                                        <br/><br/><b>Load funds into OKX futures trading wallet</b><br/><br/>
                                        OKX has two wallets: Funding and Trading. Funds deposited into OKX accounts are put into Funding wallet which is not used for trading. To be able to trade transfer funds into Trading wallet.
                                        <div className={"text-center"}><StaticImage src={"../../gatsbyimg/docs/okx_transferfunds.png"} alt={"Setup OKX API Key"} className={"border"}/></div>

                                        <br/><br/><b>How much funds do I need in the futures wallet</b><br/><br/>
                                        Maximum leverage for OKX is 125. That means that if we want to open position with size X, we need to provide equity on our account called margin equal
                                        to X/125. To start spread trade with size of 1000 dollars, we need to provide the equity worth 16 dollars on our trading account as a margin for a spread trade,
                                        8 - dollars for each of the legs of the spread trade.

                                        <br/><br/><a name={"okxleverage"} href={"#"}><b>Setting the default leverage on OKX</b></a><br/><br/>
                                        OKX manages the leverage per each individual instrument.
                                        <br/><br/>
                                        <ul className={"list-style1"}>
                                            <li>Minimum leverage: 1x</li>
                                            <li>Maximum leverage: 125x</li>
                                            <li>Default leverage: 3x</li>
                                        </ul>
                                        <br/><br/>In order to change the default leverage for currently selected instrument click on current leverage
                                        trading platform:
                                        <div className={"text-center"}><StaticImage src={"../../gatsbyimg/docs/okx_leverage1.png"}
                                                                                    alt={"Setup OKX Leverage 1"} className={"border mt-30 mb-30"}/></div>
                                        And then change the leverage to the preferred value:
                                        <div className={"text-center"}><StaticImage src={"../../gatsbyimg/docs/okx_leverage2.png"} width={300}
                                                                                    alt={"Setup OKX Leverage 2"} className={"border mt-30 mb-30"}/></div>

                                        <br/><br/><b>Important</b><br/><br/>
                                        <ul className={"list-style1"}>
                                            <li>BitSpreader currently doesn't support the margin control - if you don't have enough funds on your account available, exchange will return errors</li>
                                        </ul>

                                    </div>

                                </div>

                                <div className={"row"}>
                                    <div className={'col-md-12 text-justify'}>
                                        <br/><a href="#" name={"leverage"}/><h4><b>Leverage Setup</b></h4>
                                        BitSpreader doesn't support changing the leverage from BitSpreader user interface. In order to change the leverage you need to change the default leverage
                                        directly on the exchange. Please follow these links to find out how to update the default leverage on particular exchanges:
                                        <br/><br/>
                                        <ul className={"list-style1"}>
                                            <li><a href={"#binanceleverage"}>Binance</a></li>
                                            <li><a href={"#bitmexleverage"}>Bitmex</a></li>
                                            <li><a href={"#deribitleverage"}>Deribit</a></li>
                                            <li><a href={"#huobileverage"}>Huobi</a></li>
                                            <li><a href={"#krakenleverage"}>Kraken</a></li>
                                            <li><a href={"#kucoinleverage"}>KuCoin</a></li>
                                            <li><a href={"#okxleverage"}>OKX</a></li>
                                        </ul>
                                        <br/><br/>


                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                </section>
                <Footer/>
            </div>
        </ThemeProvider>
    )
}

export default ApiKeys;
